
import BaseCard from "@/components/ui/BaseCard.vue";
import {Credentials} from "@/model/auth/session";
import {useToast} from "vue-toastification";

export default {
  components: {BaseCard},
  setup() {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      error: null,
    }
  },
  methods: {
    async login(credentials: Credentials) {
      try {
        await this.$store.dispatch('auth/login', credentials);
        this.toast.success('Přihlášení proběhlo úspěšně.', {
          timeout: 1500,
        });
        this.$router.replace('/');
      } catch (e: any) {
        this.error = e.message;
      }
    }
  }
}
