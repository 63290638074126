import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quill_editor = _resolveComponent("quill-editor")!

  return (_openBlock(), _createBlock(_component_quill_editor, {
    toolbar: "full",
    content: $data.quillModel,
    "onUpdate:content": _cache[0] || (_cache[0] = ($event: any) => (($data.quillModel) = $event)),
    onTextChange: $options.handleInput,
    contentType: "html",
    theme: "snow"
  }, null, 8, ["content", "onTextChange"]))
}