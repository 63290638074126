import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76bcedee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_form_kit = _resolveComponent("form-kit")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_ZoomIn = _resolveComponent("ZoomIn")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$data.isEditMode)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($options.page.name), 1))
      : (_openBlock(), _createBlock(_component_form_kit, {
          key: 1,
          "submit-label": "Potvrdit",
          type: "form",
          onSubmit: $options.onFormSubmit
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormKit, {
              type: "text",
              name: "name",
              label: "Název stránky",
              value: $options.page.name
            }, null, 8, ["value"])
          ]),
          _: 1
        }, 8, ["onSubmit"])),
    _createVNode(_component_el_tooltip, {
      class: "box-item",
      effect: "dark",
      content: "Vymazat",
      placement: "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_icon, {
          class: "action-icon clickable",
          onClick: $options.onDeleteClick
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Delete)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_tooltip, {
      class: "box-item",
      effect: "dark",
      content: "Upravit",
      placement: "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_icon, {
          class: "action-icon clickable",
          onClick: $options.onEditClick
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Edit)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_tooltip, {
      class: "box-item",
      effect: "dark",
      content: "Zobrazit podstránky",
      placement: "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_link, {
          to: '/pages/detail/' + $props.id
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, { class: "action-icon clickable" }, {
              default: _withCtx(() => [
                _createVNode(_component_ZoomIn)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _: 1
    })
  ], 64))
}