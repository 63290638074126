export class Token {
  constructor(public token: string) {
    this.expires = this.parseExpires(token);
  }
  private expires: number;

  private parseExpires(token: string): number {
    const base64Url = this.token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const obj = JSON.parse(jsonPayload);
    return obj['exp'];
  }

  public getExpires(): number {
    return this.expires;
  }
}

export class User {
  constructor(public fullName: string) { }
}

export class Credentials {
  constructor(public username: string, public password: string) {
  }
}
