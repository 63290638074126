export default {
    getPages(state, getters) {
        return state.pages;
    },
    getPage: (state, getters) => (id) => {
        id = parseInt(id);
        if (state.pages === null) {
            return null;
        }
       return state.pages.find(p => p.id === id);
    }
}