import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { plugin, defaultConfig } from '@formkit/vue'
import BaseCard from "@/components/ui/BaseCard.vue";
import BaseSpinner from "@/components/ui/BaseSpinner.vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import draggable from "vuedraggable";


const app = createApp(App);

app.use(store);
app.use(router);
app.use(plugin, defaultConfig({
    theme: 'genesis',
}));
app.use(Toast);
app.use(ElementPlus)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.component('base-card', BaseCard);
app.component('base-spinner', BaseSpinner);
app.component('draggable', draggable);

app.mount('#app')
