import page from "@/store/modules/page/index";

export default {
    async loadPages(context: any) {
        const token = context.getters['auth/token'];

        const response = await fetch('/api/menu', {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error('Menu fetch failed.');
        }

        const json = await response.json();
        const payload = [];

        for (const entry of json) {
            const page = entry['Page'];
            page.sortIndex = page.sort_index;
            page.subPages = page.sub_pages;
            delete page.sort_index;
            delete page.sub_pages;
            payload.push(page);
        }

        context.commit('setPages', payload);
    },
    async deletePage(context: any, page_id: number) {
        const token = context.getters['auth/token'];

        const response = await fetch(`/api/page/${page_id}`, {
            method: 'DELETE',
            headers: { Authorization: `Bearer ${token}` }
        });

        if (!response.ok) {
            throw new Error('Page deletion failed.');
        }

        context.commit('deletePage', page_id);
    },
    async updatePageName(context: any, payload: {id: number, name: string}) {
        const token = context.getters['auth/token'];
        const response = await fetch(`/api/page`, {
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error('Page deletion failed.');
        }

        context.commit('updatePageName', payload);
    },
    async updateSortIndexes(context: any, payload: {id: number, sortIndex: number}[]) {
        const token = context.getters['auth/token'];
        const body = [];
        for (const p of payload) {
            body.push({id: p.id, sort_index: p.sortIndex})
        }

        const response = await fetch(`/api/page/sort`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error('Updating indexes failed.');
        }

        context.commit('updateSortIndexes', payload);
    },
    async createPage(context: any, name: string) {
        const token = context.getters['auth/token'];

        const response = await fetch(`/api/page`, {
            method: 'POST',
            body: JSON.stringify({
                name: name,
            }),
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error('Updating indexes failed.');
        }

        const json = await response.json();
        json['subPages'] = [];

        context.commit('addPage', json);
    }
}