import {Page, SubPageLink} from "@/store/modules/page/index";
import {SubPage} from "@/store/modules/subpage";

export default {
    setPages(state: any, payload: any) {
        state.pages = payload;
    },
    deletePage(state: any, page_id: number) {
        let i = 0;
        for (const p of state.pages) {
            if (p.id === page_id) {
                state.pages.splice(i, 1);
                return;
            }
            i++;
        }
    },
    addSubPage(state: any, payload: {pageId: number, subpageId: number, subpageName: string}) {
      const page: Page = state.pages.find(p => p.id === payload.pageId);
      page.subPages.push({
          id: payload.subpageId,
          name: payload.subpageName,
      });
    },
    updateSubPage(state: any, payload: {pageId: number, subpageId: number, subpageName: string}) {
        const page: Page = state.pages.find(p => p.id === payload.pageId);
        const subpage: SubPageLink = page.subPages.find(s => s.id === payload.subpageId);
        subpage.name = payload.subpageName;
    },
    deleteSubpage(state: any, payload: {subpageId: number}) {

    },
    updatePageName(state: any, payload: any) {
        const page = state.pages.find(p => p.id === payload.id);
        page.name = payload.name;
    },
    updateSortIndexes(state: any, payload: {id: number, sortIndex: number}[]) {
        for (const entry of payload) {
            const page: Page = state.pages.find(p => p.id === entry.id);
            page.sortIndex = entry.sortIndex;
        }
    },
    addPage(state: any, payload: Page) {
        state.pages.push(payload);
    }
}