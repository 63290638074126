import {SubPage, SubPageImage} from "@/store/modules/subpage/index";

export default {
    async loadSubPage(context: any, subpageId: number) {

        const dataResponse = await fetch(`/api/subpage/${subpageId}`);
        if (!dataResponse.ok) {
            throw new Error('SubPage data fetch failed.');
        }
        const dataJson = await dataResponse.json();
        const files: SubPageImage[] = [];
        for (const img of dataJson.images) {
            files.push({
                id: img.id,
                subpageId: img.subpage_id,
                description: img.description,
                filename: img.filename,
            })
        }

        const subpage: SubPage = {
            content: dataJson.content,
            name: dataJson.name,
            id: dataJson.id,
            pageId: dataJson.page_id,
            files: files,
        };

        context.commit('addSubPage', subpage);
        return subpage;
    },
    async deleteSubPage(context: any, subpageId: number) {
        const token = context.getters['auth/token'];

        const response = await fetch(`/api/subpage/${subpageId}`, {
            method: 'DELETE',
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error('Deleting subpage failed.');
        }

        context.commit('removeSubPage', subpageId);
    },
    async updateSubPage(context: any, payload: {id: number, name: string, content: string}) {
        const token = context.getters['auth/token'];

        const response = await fetch(`/api/subpage`, {
            method: 'PUT',
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error('Updating subpage failed.');
        }
        const responseJson = await response.json();

        context.commit('updateSubpage', payload);
        context.commit('page/updateSubPage', {
            pageId: responseJson.page_id,
            subpageId: responseJson.id,
            subpageName: responseJson.name,
        }, {root: true});
    },
    async createSubPage(context: any, payload: {pageId: number, name: string, content: string}) {
        const token = context.getters['auth/token'];
        const body = {
            page_id: payload.pageId,
            name: payload.name,
            content: payload.content,
        }

        const response = await fetch(`/api/subpage`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            throw new Error('Updating subpage failed.');
        }

        const dataJson = await response.json();
        const subpage: SubPage = {
            content: dataJson.content,
            name: dataJson.name,
            id: dataJson.id,
            pageId: dataJson.page_id,
            files: [],
        };

        context.commit('addSubPage', subpage);
        context.commit('page/addSubPage', {
            pageId: payload.pageId,
            subpageId: subpage.id,
            subpageName: subpage.name,
        }, {root: true});

        return subpage;
    },
    async createFile(context: any, payload: {file: File, description: string, subpageId: number}) {
        const token = context.getters['auth/token'];
        const formData = new FormData();
        formData.append('file', payload.file);
        formData.append('subpage_id', payload.subpageId.toString());
        formData.append('description', payload.description);

        const response = await fetch(`/api/files/subpage`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${token}`},
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Uploading image failed.');
        }
        const jsonResponse = await response.json();

        context.commit('addImg', {
            subpageId: jsonResponse.subpage_id,
            description: jsonResponse.description,
            filename: jsonResponse.filename,
            id: jsonResponse.id,
        });
    },
    async removeFile(context: any, imgId: number) {
        const token = context.getters['auth/token'];

        const response = await fetch(`/api/files/subpage/${imgId}`, {
            method: 'DELETE',
            headers: { Authorization: `Bearer ${token}`},
        });

        if (!response.ok) {
            throw Error('Error while uploading file.');
        }

        context.commit('deleteImg', imgId);
    },
    async updateSubpageImgSortIndex(context: any, images: SubPageImage[]) {
        const token = context.getters['auth/token'];
        const body = [];
        let i = 1;
        for (const img of images) {
            body.push({id: img.id, sort_index: i})
            i++;
        }

        const response = await fetch(`/api/subpage_img/sort`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        });

        if (!response.ok)
            throw new Error('Updating image sort indexes failed')

        context.commit('setSubpageImages', images);
    }
}