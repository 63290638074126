
import {Page} from "@/store/modules/page";
import {useToast} from "vue-toastification";

export default {
  props: ['id'],
  emits: ['pageDeleted'],
  data() {
    return {
      isEditMode: false,
      toast: useToast(),
    }
  },
  computed: {
    page(): Page {
      const pages: Page[] = this.$store.getters['page/getPages'];
      return pages.find(p => p.id === this.id);
    }
  },
  methods: {
    onEditClick() {
      this.isEditMode = !this.isEditMode;
    },
    async onFormSubmit(data) {
      try {
        await this.$store.dispatch('page/updatePageName', {id: this.id, name: data.name});
        this.toast.success('OK', {
          timeout: 1500,
        });
        this.isEditMode = false;
      } catch (e) {
        console.error(e);
        this.toast.error('Jejda. Něco se pokazilo. Obnovte stránku.', {
          timeout: 1500,
        });
      }
    },
    async onDeleteClick() {
      if (confirm(`Opravdu si přejete vymazat stránku "${this.page.name}"?`)) {
        this.$emit('pageDeleted', this.id);
        await this.$store.dispatch('page/deletePage', this.id);
      }
    }
  }
}
