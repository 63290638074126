<script>
import TheHeader from "@/components/layout/TheHeader.vue";

export default {
  components: {TheHeader},
  async created() {
    this.$store.dispatch('auth/tryLogin');
    await this.$store.dispatch('page/loadPages');
  },
}
</script>

<template>
  <the-header></the-header>
  <div class="container text-center">
    <router-view/>
  </div>
</template>

<style lang="scss">
@import "scss/style";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.clickable {
  cursor: pointer;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>