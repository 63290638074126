
import SubPageForm from "@/components/subpage/SubPageForm.vue";
import {useToast} from "vue-toastification";
import {SubPage} from "@/store/modules/subpage";

export default {
  components: {SubPageForm},
  props: ['pageId'],
  data() {
    return {
      toast: useToast(),
      isLoading: false,
    }
  },
  methods: {
    async onFormSubmit(data) {
      if (this.isLoading) {
        console.log('IsLoading is true.');
        return;
      }
      this.isLoading = true;
      try {
        data['pageId'] = parseInt(this.pageId);
        const subpage: SubPage = await this.$store.dispatch('subpage/createSubPage', data);
        this.toast.success('Podstránka byla úspěšně založena.', {
          timeout: 1500,
        });
        this.$router.replace(`/subpage/update/${subpage.id}`);
      } catch (e) {
        console.log(e);
        this.toast.error('Ajaj. Něco se pokazilo.', {
          timeout: 1500,
        });
      }
      this.isLoading = false;
    }
  }
}
