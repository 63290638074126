import {SubPage} from "@/store/modules/subpage/index";

export default {
    getSubpage: (state, getters) => (id): null|SubPage => {
        id = parseInt(id);
        if (state.subPages === null) {
            return null;
        }
        return state.subPages.find(s => s.id === id);
    }
}