
import {QuillEditor} from "@vueup/vue-quill";

export default {
  components: {
    QuillEditor,
  },
  props: ['context'],
  data() {
    return {
      formContext: this.context,
      quillModel: this.context._value,
    }
  },
  methods: {
    handleInput(range, oldRange, source) {
      this.context.node.input(this.quillModel);
    }
  }
}
