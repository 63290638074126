import { createStore } from 'vuex'
import auth from './modules/auth/index';
import kv from './modules/kv/index';
import page from './modules/page/index';
import subpage from './modules/subpage/index';
export default createStore({
  modules: {
    auth,
    kv,
    page,
    subpage,
  }
})
