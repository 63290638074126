import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_kit = _resolveComponent("form-kit")!

  return ($data.readyToRender)
    ? (_openBlock(), _createBlock(_component_form_kit, {
        key: 0,
        "submit-label": $options.submitLabel,
        type: "form",
        onSubmit: $options.onFormSubmit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_form_kit, {
            type: "text",
            name: "name",
            placeholder: "Název",
            validation: "required|maxlength:50",
            value: $data.name,
            "validation-messages": {
                maxLength: 'Maximální délka překročena',
                required: 'Toto pole je povinné',
              }
          }, null, 8, ["value"]),
          _createTextVNode(" Využito prostoru: " + _toDisplayString($data.occupied) + "% ", 1),
          _createVNode(_component_form_kit, {
            "outer-class": {
    'formkit-outer': false,
    'center': true,
    },
            "wrapper-class": {'formkit-wrapper': false},
            "inner-class": {'formkit-inner': false},
            type: $data.quillInput,
            onInput: $options.onQuillInput,
            validation: "maxlength:65535",
            "validation-messages": {
                maxLength: 'Maximální délka překročena',
                required: 'Toto pole je povinné',
              },
            value: $data.content,
            name: "content"
          }, null, 8, ["type", "onInput", "value"])
        ]),
        _: 1
      }, 8, ["submit-label", "onSubmit"]))
    : _createCommentVNode("", true)
}