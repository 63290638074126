
import {mapGetters} from "vuex";
import {Page} from "@/store/modules/page";

export default {
  props: ['id'],
  computed: {
    ...mapGetters({
      getPage: 'page/getPage',
    }),
    page(): Page {
      return this.getPage(this.id);
    },
    routerLink(): string {
      return `/subpage/create/${this.id}`;
    }
  }
}
