import {Credentials, Token} from "@/model/auth/session";
import {TokenResponse} from "@/model/http/session";

let timer: any;

export default {
    async login(context: any, payload: Credentials) {
        const url = "/api/token";
        const data = new FormData();
        data.append('username', payload.username);
        data.append('password', payload.password);
        const response = await fetch(url, {
            method: 'POST',
            body: data,
        });

        if (!response.ok) {
            throw new Error('Špatné přihlašovací údaje.');
        }

        const json: TokenResponse = await response.json();
        const token = new Token(json.access_token);

        console.log("Token", token);

        localStorage.setItem('token', token.token);
        localStorage.setItem('tokenExpiration', token.getExpires().toString());

        const expiresIn = token.getExpires() * 1000 - new Date().getTime();

        timer = setTimeout(function () {
            context.dispatch('autoLogout');
        }, expiresIn);

        context.commit('setToken', token.token);
    },
    tryLogin(context: any) {
      const token = localStorage.getItem('token');
      const expires = localStorage.getItem('tokenExpiration');
      if (token === null || expires === null) {
          return;
      }

      const expiresIn = +expires * 1000 - new Date().getTime();
      if (expiresIn < 0) {
          return;
      }
      timer = setTimeout(function () {
          console.log('AutoLogout from TryLogin');
            context.dispatch('autoLogout');
            }, expiresIn);

        context.commit('setToken', token);
    },
    logout(context: any) {
        console.log('logout');
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');

        clearTimeout(timer);

        context.commit('setToken', {
            token: null
        });
    },
    autoLogout(context: any) {
        context.dispatch('logout');
        context.commit('setAutoLogout');
    }
}