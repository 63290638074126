export default {
  isAuthenticated(state: any) {
    console.log('isAuthenticated');
    return !!state.token;
  },
  didAutoLogout(state: any) {
    return state.didAutoLogout;
  },
  token(state: any): string {
    return state.token;
  },
}