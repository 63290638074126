
import {mapGetters} from "vuex";
import SubPageForm from "@/components/subpage/SubPageForm.vue";
import {useToast} from "vue-toastification";
import PageListEntry from "@/components/page/PageListEntry.vue";
import subpage from "@/store/modules/subpage";

export default {
  components: {PageListEntry, SubPageForm},
  props: ['id'],
  data() {
    return {
      toast: useToast(),
      subpage: null,
      fileModel: null,
      textareaModel: null,
    };
  },
  computed: {
    ...mapGetters({
      subpageGetter: 'subpage/getSubpage',
    }),
  },
  async created() {
    if (!this.subpageGetter(this.id)) {
      await this.$store.dispatch('subpage/loadSubPage', this.id);
    }
    this.subpage = this.subpageGetter(parseInt(this.id));
  },
  methods: {
    async onDeleteSubpageClick(){
      if (!confirm("Opravtu chcete vymazat podstránku?")){
        return;
      }
      try{
        await this.$store.dispatch('subpage/deleteSubPage', this.id);
        this.toast.success('Podstránka byla úspěšně vymazána.', {
          timeout: 1500,
        });
        await this.$store.dispatch('page/loadPages');
        console.log(this.subpage);
        this.$router.replace(`/pages/detail/${this.subpage.pageId}`);
      } catch (e){
        console.error(e);
        this.toast.error('Ajaj. Něco se pokazilo.', {
          timeout: 1500,
        });
      }
    },
    async onFormSubmit(data: {name: string, content: string}) {
      data['id'] = parseInt(this.id);

      try {
        await this.$store.dispatch('subpage/updateSubPage', data);
        this.toast.success('Upravení podstránky proběhlo úspěšně.', {
          timeout: 1500,
        });
      } catch (e) {
        console.error(e);
        this.toast.error('Ajaj. Něco se pokazilo.', {
          timeout: 1500,
        });
      }
      this.subpage = this.subpageGetter(parseInt(this.id));
    },
    async onImgFormSubmit(data) {
      try {
        await this.$store.dispatch('subpage/createFile', {
          file: data['img'][0]['file'],
          description: data.description ? data.description : ' ',
          subpageId: parseInt(this.id),
        });
        this.toast.success('Obrázek se nahrál úspěšně.', {
          timeout: 1500,
        });
      } catch (e) {
        console.error(e);
        this.toast.error('Ajaj. Něco se pokazilo.', {
          timeout: 1500,
        });
      }
      this.fileModel = null;
      this.textareaModel = null;
      this.subpage = this.subpageGetter(parseInt(this.id));
    },
    async onDeleteImgClick(imgId: number) {
      if (confirm('Potvrďte vymazání obrázku')) {
        try {
          await this.$store.dispatch('subpage/removeFile', imgId);
          this.toast.success('Obrázek se vymazal úspěšně.', {
            timeout: 1500,
          });
        } catch (e) {
          console.error(e);
          this.toast.error('Ajaj. Něco se pokazilo.', {
            timeout: 1500,
          });
        }
      }
      this.subpage = this.subpageGetter(parseInt(this.id));
    },
    async onImgSortUpdateClick() {
      try {
        await this.$store.dispatch('subpage/updateSubpageImgSortIndex', this.subpage.files);
        this.toast.success('Změna pořadí obrázků se uložila.', {
          timeout: 1500,
        });
      } catch (e) {
        console.error(e);
        this.toast.error('Ajaj. Něco se pokazilo.', {
          timeout: 1500,
        });
      }
      this.subpage = this.subpageGetter(parseInt(this.id));
    }
  }
}
