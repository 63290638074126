
import QuillInput from "@/components/form/QuillInput.vue";
import {createInput} from "@formkit/vue";
import BaseSpinner from "@/components/ui/BaseSpinner.vue";
import {useToast} from "vue-toastification";
import {KvEntity} from "@/model/entity";

export default {
  components: {BaseSpinner},
  props: ['kvKey'],
  setup() {
    return {
      toast: useToast(),
    }
  },
  data(): any {
    return {
      key: this.kvKey,
      quillInput: createInput(QuillInput),
      occupied: 0,
      showSpinner: true,
      kv: null,
    }
  },
  async created() {
    const response = await fetch(`/api/kv/${this.kvKey}`);
    if (!response.ok)
      throw new Error('Fetch failed.');
    const json = await response.json();

    const kvEntity = new KvEntity(json.id, json['value']);
    this.kv = kvEntity;
    this.showSpinner = false;
  },
  methods: {
    async onFormSubmit(data: any) {
      this.showSpinner = true;
      try {
        await this.$store.dispatch('kv/updateKv', {
          name: 'homepage',
          value: data.quill,
        });
        this.toast.success('Hlavní stránka byla úspěšně upravena.', {
          timeout: 2000,
        });
      } catch (e) {
        console.error(e);
        this.toast.error('Chyba při upravování.', {
          timeout: 2000,
        });
      }
      this.showSpinner = false;
    },
    onQuillInput(inpt: string) {
      this.occupied = (inpt.length / 65635 * 100).toFixed(2);
    }
  }
}
