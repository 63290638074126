import mutations from './mutations';
import actions from './actions';
import getters from "./getters";

export class SubPage {
    public id: number;
    public pageId: number;
    public name: string;
    public content: string;
    public files: SubPageImage[];
}

export class SubPageImage {
    public id: number;
    public subpageId: number;
    public description: string;
    public filename: string;
}

export default {
    namespaced: true,
    state(): {subPages: SubPage[]} {
        return {
            subPages: [],
        }
    },
    actions,
    mutations,
    getters,
}