import {SubPage, SubPageImage} from "@/store/modules/subpage/index";
import subPageForm from "@/components/subpage/SubPageForm.vue";

export default {
    addSubPage(state: any, subpage: SubPage) {
        if (!state.subPages.some(s => s.id === subpage.id)) {
            state.subPages.push(subpage);
        }
    },
    removeSubPage(state: any, subpageId: number) {
        let i = 0;
        for (const s of state.subPages) {
            if (s.id === subpageId)
            {
                state.subPages.splice(i, 1);
                return;
            }
        }
    },
    updateSubpage(state: any, payload: {id: number, name: string, content: string}) {
        console.log(payload);
        console.log(state.subPages);
        const subpage: SubPage = state.subPages.find(s => s.id === payload.id);
        subpage.name = payload.name;
        subpage.content = payload.content;
    },
    deleteImg(state: any, imgId: number) {
        for (const subpage of state.subPages) {
            let i = 0;
            for (const img of subpage.files) {
                if (img.id === imgId) {
                    subpage.files.splice(i, 1);
                }
                i++;
            }
        }
    },
    addImg(state: any, payload: SubPageImage) {
        const subpage: SubPage = state.subPages.find(s => s.id === payload.subpageId);
        subpage.files.push(payload);
    },
    setSubpageImages(state: any, payload: SubPageImage[]) {
        const subpage: SubPage = state.subPages.find(s => s.id === payload[0].subpageId);
        subpage.files = payload;
    }
}