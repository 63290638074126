
import {createInput} from "@formkit/vue";
import QuillInput from "@/components/form/QuillInput.vue";
import {SubPage} from "@/store/modules/subpage";
import {mapGetters} from "vuex";

export default {
  emits: ['onSubmit'],
  props: {
    subpageId: {
      required: false,
    }
  },
  data() {
    return {
      quillInput: createInput(QuillInput),
      occupied: 0,
      name: null,
      content: null,
      readyToRender: false,
    }
  },
  computed: {
      submitLabel() {
        return this.subpageId ? 'Upravit' : 'Vytvořit';
      },
      ...mapGetters({
        subpageGetter: 'subpage/getSubpage',
      }),
  },
  methods: {
    onQuillInput(inpt: string) {
      this.occupied = (inpt.length / 65635 * 100).toFixed(2);
    },
    onFormSubmit(data: any) {
      this.$emit('onSubmit', data);
    }
  },
  async created() {
    if (this.subpageId) {
      let subpage: SubPage = this.subpageGetter(this.subpageId);
      console.log('First', subpage);
      if (!subpage) {
        subpage = await this.$store.dispatch('subpage/loadSubPage', this.subpageId);
        console.log('Second', subpage);
      }
      this.name = subpage.name;
      this.content = subpage.content;
    } else {
      this.name = '';
      this.content = '';
    }
    this.onQuillInput(this.content);
    this.readyToRender = true;
  }
}
