
import PageListEntry from "@/components/page/PageListEntry.vue";
import {useToast} from "vue-toastification";

export default {
  components: {PageListEntry},
  data() {
    return {
      pages: null,
      toast: useToast(),
      createPageModel: '',
    }
  },
  async created() {
    this.pages = await this.loadPages();
  },
  methods: {
    saveSortIndexes() {
      const payload = [];
      let i = 1;
      for (const p of this.pages) {
        payload.push({
          id: p.id,
          sortIndex: i,
        })
        i++;
      }

      try {
        this.$store.dispatch('page/updateSortIndexes', payload);
        this.toast.success('Hotovo', {
          timeout: 1500,
        });
      } catch (e) {
        console.error(e);
        this.toast.error('Jejda. Něco se pokazilo.', {
          timeout: 1500,
        });
      }
    },
    async onFormSubmit(data) {
      try {
        await this.$store.dispatch('page/createPage', data.name);
        this.pages = await this.loadPages();
        this.toast.success('Stránka úspěšně přidána', {
          timeout: 1500,
        });
      } catch (e) {
        console.error(e);
        this.toast.error('Jejda. Něco se pokazilo.', {
          timeout: 1500,
        });
      }
      this.createPageModel = '';
    },
    async loadPages() {
      let pages = this.$store.getters['page/getPages'];
      if (pages === null) {
        await this.$store.dispatch('page/loadPages');
      }
      pages = this.$store.getters['page/getPages'];

      const result = []
      if (pages === null)
        return null;
      for (const p of pages) {
        result.push({
          id: p.id
        })
      }

      return result;
    },
    onPageDeleted(page_id: number) {
      let i = 0;
      for (const p of this.pages) {
        if (p.id === page_id) {
          this.pages.splice(i, 1);
          return;
        }
        i++;
      }
    }
  }
}
