export default {
    async updateKv(context: any, payload: { name: string, value: string }) {
        const token = context.getters['auth/token'];
        const response = await fetch('/api/kv', {
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error('Update failed');
        }
    }
}