import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/pages/auth/Login.vue';
import store from '../store/index';
import Homepage from '@/pages/Homepage.vue';
import KvPage from '@/pages/KvPage.vue';
import ListPages from "@/pages/page/ListPages.vue";
import PageDetail from "@/pages/page/PageDetail.vue";
import CreateSubpage from "@/pages/subpage/CreateSubpage.vue";
import UpdateSubpage from "@/pages/subpage/UpdateSubpage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'homepage',
    component: Homepage,
    meta: {requiresAuth: true},
  },
  {
    path: '/kv/:kvKey',
    props: true,
    component: KvPage,
    meta: {requiresAuth: true},
  },
  {
    path: '/pages',
    component: ListPages,
    meta: {requiresAuth: true},
  },
  {
    path: '/pages/detail/:id',
    component: PageDetail,
    props: true,
    meta: {requiresAuth: true},
  },
  {
    path: '/subpage/create/:pageId',
    component: CreateSubpage,
    props: true,
    meta: {requiresAuth: true},
  },
  {
    path: '/subpage/update/:id',
    component: UpdateSubpage,
    props: true,
    meta: {requiresAuth: true},
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/:notFound(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
})

router.beforeEach(function(to, _, next) {
  if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
    console.log('redirect');
    next('/login');
  } else {
    next();
  }
});

export default router
