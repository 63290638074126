import mutations from './mutations';
import actions from './actions';
import getters from "./getters";

export class PageState {
    public pages: Page[] | null;
}

export class Page {
    public id: number;
    public name: string;
    public sortIndex: number;
    public subPages: SubPageLink[];
}

export class SubPageLink {
    public id: number;
    public name: string;
}


export default {
    namespaced: true,
    state(): PageState {
        return {
            pages: null,
        }
    },
    getters,
    actions,
    mutations,
}